<template>
    <div class="sdk-card">
        <a :class="info.href ? '' : 'disabled'" :href="info.href || 'javascript:void(0)'" target="_blank" rel="noopener noreferrer" @click="handleCardClick">
            <div class="card-title">
                <p>{{ info.name }}</p>
                <i class="iconfont">&#xe638;</i>
            </div>
            <p class="card-description">
                {{ $t('sdk.' + info.code) || $t('sdk.default') }}
            </p>
        </a>
    </div>
</template>

<script>
export default {
    name: 'SdkCard',
    props: [ 'info' ],
    methods: {
        handleCardClick(e) {
            if (!this.info.href) {
                e.preventDefault()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.sdk-card {
    padding: 0 8px 16px;

    a {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        background: #e7e7e7;
        display: flex;
        flex-direction: column;
        text-decoration: none;
        padding: 25px 17px;

        .card-title {
            display: flex;
            align-items: center;
            color: var(--p);

            p {
                width: 1%;
                flex-grow: 1;
                font-size: 22px;
                font-weight: bold;
                margin: 0;
            }
        }
        .card-description {
            flex-grow: 1;
            font-size: 12px;
            line-height: 1.75;
            color: #7C7C7C;
            margin-top: 15px;
            margin-bottom: 0;
        }
        &:hover {
            background: #eeeeee;
            
            .card-title {
                color: #ffae4a;
            }
        }
        &:active {
            background: #e0e0e0;
        }
        &.disabled {
            background: #e7e7e7;
            cursor: default;

            .card-title {
                color: #747474;
            }
        }
    }
}
</style>