<template>
    <div class="download">
        <div id="download-editor" class="editor-sec">
            <div class="container">
                <div class="download-info">
                    <img class="editor-logo" draggable="false" src="@/assets/logo/editor-logo.png" alt="">
                    <h1>{{ $t('page.download.editor.title') }}</h1>
                    <p>{{ $t('page.download.editor.intro') }}</p>
                </div>
                <div class="version-info">
                    <p>{{ $t('page.download.editor.lastest')  }} {{ versionInfo.version }}</p>
                </div>
                <div class="btn-group">
                    <a class="big-btn" :href="'https://res.fairygui.com/FairyGUI-Editor_' + versionInfo.version + '.zip'"><i class="iconfont">&#xe600;</i> Windows</a>
                    <a class="big-btn" :href="'https://res.fairygui.com/FairyGUI-Editor_Mac_' + versionInfo.version + '.zip'"><i class="iconfont">&#xe6b2;</i> MacOS</a>
                </div>
                <div class="release-info">
                    <router-link to="/release/editor">{{ $t('page.download.common.release_note') }} <i class="iconfont">&#xe638;</i></router-link>
                </div>
                <div class="screenshot">
                    <img draggable="false" src="@/assets/decoration/screenshot.png" alt="">
                </div>
            </div>
        </div>
        <div id="download-sdk" class="sdk-sec">
            <div class="title">
                <div class="container">
                    <div class="download-info">
                        <img draggable="false" src="@/assets/logo/sdk-logo.svg" alt="">
                        <h1>FairyGUI SDK</h1>
                        <p>{{ $t('page.download.sdk.intro') }}</p>
                    </div>
                    <div class="sec-title">
                        <h2>{{ $t('page.download.sdk.official_title') }}</h2>
                        <p>{{ $t('page.download.sdk.official_description') }}</p>
                    </div>
                    <div class="sdk-list">
                        <sdk-card v-for="sdk in sdkInfo.official" :key="sdk.name" :info="sdk"></sdk-card>
                    </div>
                    <div class="sec-title">
                        <h2>{{ $t('page.download.sdk.third_party_title') }}</h2>
                        <p>{{ $t('page.download.sdk.third_party_description') }}</p>
                    </div>
                    <div class="sdk-list">
                        <sdk-card v-for="sdk in sdkInfo['third-party']" :key="sdk.name" :info="sdk"></sdk-card>
                    </div>
                </div>
            </div>
        </div>
        <div class="faq-sec">
            <div class="container">
                <question-and-answer :info="faqInfo"></question-and-answer>
            </div>
        </div>
    </div>
</template>

<script>
import SdkCard from '../components/common/SdkCard.vue'
import QuestionAndAnswer from '../components/common/QuestionAndAnswer.vue'

import sdkInfo from '../data/sdk-info.json'
import faqInfo from '../data/download-faq.json'
import versionInfo from '../data/download.json'

export default {
    name: 'Download',
    data: () => {
        return {
            sdkInfo,
            faqInfo,
            versionInfo
        }
    },
    components: {
        SdkCard,
        QuestionAndAnswer,
    }
}
</script>

<style lang="scss" scoped>
.download-info {
    padding-top: 98px;
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 110px;
        height: auto;
    }
    h1 {
        margin: 0;
        font-style: italic;
        font-size: 47px;
        line-height: 1.5;
        margin-top: 10px;
        margin-bottom: 4px;
    }
    p {
        margin: 0;
        font-size: 22px;
        line-height: 1;
    }
}

.release-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
        margin-top: 15px;
        text-decoration: none;
        color: #e68312;

        &:hover {
            color: var(--p);
        }
    }
}

.version-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
        padding-top: 15px;
        margin: 0;
        font-size: 18px;
    }
}

.btn-group {
    display: flex;
    justify-content: center;
    margin-top: 34px;

    .big-btn {
        margin: 0 9px;

        .iconfont {
            margin-right: 6px;
        }
    }
    @media (max-width: 576px) {
        flex-direction: column;

        .big-btn {
            width: 100%;
            margin: 0;
            margin-bottom: 15px;
        }
    }
}

.editor-sec {
    .container {
        background: url(../assets/decoration/pop-white.svg) no-repeat;
        background-size: 100% auto;
    }
    .screenshot {
        margin-top: 60px;
        height: 175px;
        overflow: hidden;
        text-align: center;

        img {
            border-radius: 4px;
            width: 100%;
            max-width: 840px;
        }
    }
}

.sdk-sec {
    .title {
        background: linear-gradient(180deg, rgba(233,233,233,1) 0%, rgba(255,255,255,1) 100%);
        margin-bottom: 10px;
    }
    .sec-title {
        margin-top: 70px;
        margin-bottom: 50px;

        h2 {
            line-height: 1;
            font-size: 36px;
            margin: 0;
            margin-bottom: 5px;
        }
        p {
            font-size: 21px;
            line-height: 1.6;
            margin: 0;
        }
    }
    .sdk-list {
        display: flex;
        width: calc(100% + 16px);
        margin-left: -8px;
        flex-wrap: wrap;

        .sdk-card {
            width: 25%;
            
            @media (max-width: 768px) {
                width: 50%;
            }
            @media (max-width: 576px) {
                width: 100%;
            }
        }
    }
}

.faq-sec {
    margin-top: 70px;
    
    .container {
        .faq {
            padding: 55px 0;
            border-top: 4px solid rgba(#000, 0.13);
        }
    }
}
</style>